import Reveal from 'reveal.js';
import RevealNotes from 'reveal.js/plugin/notes/notes'
import 'reveal.js/dist/reveal.css';
// import 'reveal.js/dist/theme/black.css';
import 'reveal.js/dist/theme/league.css';

const deck = new Reveal({
    plugins: [ RevealNotes ]
});
deck.initialize();
